img, span, p, h1, h2, h3, h4, h5, h6, a, canvas {
  -webkit-animation: fadein 400ms cubic-bezier(0.33, 1, 0.68, 1); /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 400ms cubic-bezier(0.33, 1, 0.68, 1); /* Firefox < 16 */
      -ms-animation: fadein 400ms cubic-bezier(0.33, 1, 0.68, 1); /* Internet Explorer */
       -o-animation: fadein 400ms cubic-bezier(0.33, 1, 0.68, 1); /* Opera < 12.1 */
          animation: fadein 400ms cubic-bezier(0.33, 1, 0.68, 1);
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
