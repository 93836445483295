.quill {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border: none;
}

.ql-container {
  display: flex;
  overflow: hidden;
  font-family: 'IBM Plex Sans', sans-serif;
  box-sizing: border-box;
}

.ql-editor {
  height: inherit;
  max-height: inherit;
  color: #E2E2E2;
  text-align: left;
	white-space: pre-wrap;
	word-wrap: break-word;
}