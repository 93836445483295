/* ibm-plex-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url('./fonts/ibm-plex-sans-v7-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/ibm-plex-sans-v7-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* ibm-plex-sans-500 - latin-ext_latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
      url('./fonts/ibm-plex-sans-v7-latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/ibm-plex-sans-v7-latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* ibm-plex-sans-600 - latin-ext_latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
    url('./fonts/ibm-plex-sans-v7-latin-ext_latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/ibm-plex-sans-v7-latin-ext_latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* ibm-plex-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url('./fonts/ibm-plex-sans-v7-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/ibm-plex-sans-v7-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
