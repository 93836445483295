.parsed-link {
  color: rgb(182, 142, 247);
}

.fade-dark::after {
  content: "";
  position: absolute;
  width: 90%;
  height: 100px;
  bottom: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(15, 15, 16, 0), rgba(15, 15, 16, 1) 60%);
}
